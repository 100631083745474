export class BottomNotification
{
    // Shows notification at bottom of the page, for example "accept cookies"
    constructor(elm)
    {
        let yesButton = elm.querySelector('button[type="submit"]');
        let closeButton = elm.querySelector('.close.icon-cross');
        if (yesButton) {
            yesButton.addEventListener('click', () => {
                let d = new Date();
                d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
                document.cookie = 'cookie_eaten=true;expires=' + d.toUTCString() + ';path=/';
                elm.hide();
            });
        }
        if (closeButton) {
            closeButton.addEventListener('click', () => {
                elm.hide();
            });
        }
    }
}